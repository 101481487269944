const genericContactFormError = "We weren't able to process your request, please try again. Alternatively, you can call us.";

function validateFormInput() {
    const firstName = document.getElementById('firstName').value.trim();
    const lastName = document.getElementById('lastName').value.trim();
    const emailAddress = document.getElementById('emailAddress').value.trim();
    const description = document.getElementById('description').value.trim();
    const phone = document.getElementById('phone').value.trim();
    const city = document.getElementById('city').value.trim();

    if (firstName === "" || lastName === "" || emailAddress === "" || description === "" || phone === "" || city === "") {
        return "Missing information. Please enter values for name, email, city, phone and description";
    }

    const descriptionContent = description.replace(/(\r\n|\n|\r)/g, "  ");
    if (descriptionContent.length > 1800) {
        return "Description should only have 1800 characters.";
    }

    const emailRegex = /^[\w\-.\+]+@([\w\-]+\.)+[\w\-]{2,}$/;
    const emailValid = emailRegex.test(emailAddress.toLowerCase());
    if (!emailValid) {
        return "Invalid email address format. Please enter a valid email address."
    }

    // Must be different names
    if (!firstName.localeCompare(lastName)) {
        return "Oops. First & last names should be different values.";
    }

    // Allow + at beginning then 7 to 18 numbers (after spaces, brackets, hyphens removed)
    var phoneRegEx = new RegExp(/^(\+)?\d{7,18}$/);

    // Strip spaces, hyphen, brackes using regex replace
    var newPhoneVal = phone.replace(/[\s()-]/g, '')

    //Check for 10 digit phone number
    var phoneValid = phoneRegEx.test(newPhoneVal);

    if (phoneValid) {
        document.getElementById('phone').value = newPhoneVal;
    } else {
        return ("Phone Number Error! Enter a value consisting of 7 to 18 numbers including country and area codes.");
    }

    return null;
}

function hideAllErrorMessages() {
    const errorDivs = document.querySelectorAll("form.contact-form-wrapper .error-message");
    errorDivs.forEach(el => {
        el.innerHTML = "";
    });
}

function handleErrors(errorMessage) {
    document.querySelector(".error-message").innerHTML = errorMessage;
}

function editSubmitButton(buttonText, isDisabled) {
    const submitButton = document.querySelector("form.contact-form-wrapper .btn-submit-contact-form");
    submitButton.disabled = isDisabled;
    submitButton.textContent = buttonText;
}

function handleSubmit() {
    hideAllErrorMessages();
    const inputError = validateFormInput();
    if (inputError !== null) {
        handleErrors(inputError);
        return;
    }

    editSubmitButton("Processing...", true);
    $.ajax({
        type: 'POST',
        url: "/umbraco/Surface/Contact/SubmitForm",
        data: $('form').serialize(),
        success: function (result) {
            const data = JSON.parse(result); // see ContactFormResultModel in .cs

            if (data && data["IsSuccess"]) {
                editSubmitButton("Inquiry submitted! You will now be redirected to another page.", true);
                window.location.href = '/thank-you';
                return;
            } else {
                editSubmitButton("Submit", false);
                const errorMessage = data && data["ErrorMessage"]
                    ? data["ErrorMessage"]
                    : genericContactFormError;
                handleErrors(errorMessage);
            }
        }
    });
}

document.addEventListener('submitContactForm', handleSubmit, false);