var waitInitReviewSlider = setInterval(() => {
	if ($(".macro-review-slider")[0]) {
		initReviewSliderJs();
		clearInterval(waitInitReviewSlider);
	}
}, window.app.waitingTimeMilliSeconds);

var waitInitCourseSlider = setInterval(() => {
	if ($(".courses-slider")[0]) {
		initCourseSliderJs();
		clearInterval(waitInitCourseSlider);
	}
}, window.app.waitingTimeMilliSeconds);

var waitInitTaskSlider = setInterval(() => {
	if ($(".macro-contact-us-mobile.topnavigation")[0]) {
		initTaskSliderJs();
		clearInterval(waitInitTaskSlider);
	}
}, window.app.waitingTimeMilliSeconds);

function showGliderItems(mainSelector) {
	const gliderWrapper = document.querySelector(mainSelector + ' .glider');
	gliderWrapper.querySelectorAll('.item').forEach((item) => {
		item.classList.remove("invisible");
	});
}

function initReviewSliderJs() {
	const mainSelector = '.macro-review-slider';
	const element = document.querySelector(mainSelector);
	const parent = $(element);

	if (parent.data('multiline') != "1") {
		new Glider(document.querySelector('.macro-review-slider .glider'), {
			draggable: true,
			slidesToShow: 5,
			slidesToScroll: 5,
			rewind: true,
			dots: `${mainSelector} .dots`,
			arrows: {
				prev: `${mainSelector} .glider-prev`,
				next: `${mainSelector} .glider-next`
			},
			responsive: [
				{
					breakpoint: 1400,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 300,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
			]
		});
		showGliderItems(mainSelector);
	}
	
}

function initCourseSliderJs() {
	const mainSelector = '.courses-slider.mobile';
	new Glider(document.querySelector(`${mainSelector} .glider`), {
		draggable: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		rewind: true,
		dots: `${mainSelector} .dots`,
		arrows: {
			prev: `${mainSelector} .glider-prev`,
			next: `${mainSelector} .glider-next`
		},
		responsive: [
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				}
			},
			{
				breakpoint: 350,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				}
			},
			{
				breakpoint: 300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
		]
	});
	showGliderItems(mainSelector);
}

function initTaskSliderJs() {
	const mainSelector = '.macro-contact-us-mobile.topnavigation';
	new Glider(document.querySelector(`${mainSelector} .glider`), {
		draggable: true,
		slidesToShow: 5,
		slidesToScroll: 5,
		rewind: true,
		dots: `${mainSelector} .dots`,
		arrows: {
			prev: `${mainSelector} .glider-prev`,
			next: `${mainSelector} .glider-next`
		},
		responsive: [
			{
				breakpoint: 580,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				}
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				}
			},
			{
				breakpoint: 300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
		]
	});
	showGliderItems(mainSelector);
}