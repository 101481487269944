const desktopNavToggler = document.querySelector('#desktop-nav-toggler');
const mobileNavToggler = document.querySelector('#mobile-nav-toggler');
const mobileMenu = document.querySelector('#mobile-menu-nav');
const mobileMenuLinks = document.querySelectorAll('.menu-link');
const mobileMenuHeader = document.querySelector('#menu-header');
const mobileMenuTitleLink = document.querySelector('#menu-title-link');
const mobileMenuTitle = document.querySelector('#menu-title');
const mobileMenuBack = document.querySelector('#menu-back');
const mobileMenuReset = document.querySelector('#menu-reset');
const mobilePayButton = document.querySelector('#mobile-pay-btn');
const mobileSearchButton = document.querySelector('#mobile-search-btn');
const mobileSearchDialog = document.querySelector('#search-dialog');
// const topSearchInput = document.querySelector('#top-search-input');
let activeMenu = [];

const windowDetector = () => {
	window.addEventListener('click', (e) => {
		if (
			// e.target.matches('#mobile-nav .container') ||
			e.target.matches('#desktop-nav-toggler') ||
			e.target.closest('#desktop-nav-toggler') ||
			e.target.matches('#mobile-nav-toggler') ||
			e.target.closest('#mobile-nav-toggler') ||
			e.target.matches('.top-menu-btn') ||
			e.target.closest('.menu-link') ||
			e.target.matches('.menu-link') ||
			e.target.matches('#menu-back') ||
			e.target.matches('#menu-reset') ||
			e.target.matches('#menu-title') ||
			e.target.matches('#menu-header') ||
			e.target.closest('#menu-header') ||
			e.target.matches('#menu-title-link') ||
			e.target.closest('#menu-title-link') ||
			e.target.matches('#pay-button-mobile') ||
			e.target.closest('#mobile-search-btn') ||
			e.target.matches('#mobile-search-btn') ||
			e.target.matches('#search-dialog *')
             ||
            e.target.matches('#top-search-input')

		) {
			return;
		}

		mobileMenu.classList.remove('show');
        $('.search-list').each(function () {
            $(this).removeClass('show');
        });
        mobileSearchDialog.classList.remove('show');
	});
};

// Mobile Navigation
const hideAllMenus = () => {
	document.querySelectorAll('.menu-link').forEach((menuLink) => menuLink.classList.remove('show'));
};

const getLastMenu = () => activeMenu[activeMenu.length - 1];

const getPrevMenu = () => {
	activeMenu.pop();
	hideAllMenus();
	document.querySelector('#pay-dropdown').classList.remove('show');
	const lastMenu = getLastMenu();
	if (lastMenu) {
		showPrevMenu(lastMenu);
	} else {
		showRootMenu();
	}
};

const showNextMenu = (nextMenu) => {
	hideAllMenus();
	showSubMenuHeader();
	document.querySelector('#pay-dropdown').classList.remove('show');
	nextMenu.querySelectorAll(':scope>li>a.menu-link').forEach((link) => link.classList.add('show'));
};

const showPrevMenu = (lastMenu) => {
	hideAllMenus();
	document.querySelectorAll(`[data-submenu="${lastMenu}"]>li>a.menu-link`).forEach((link) => link.classList.add('show'));
	showSubMenuHeader();
};

const showSubMenuHeader = () => {
	mobileMenuHeader.classList.add('show');
	const lastMenu = getLastMenu();
	mobileMenuTitle.innerText = lastMenu;
	const menu = document.querySelector(`[data-menu="${lastMenu}"]`);
	const headerLink = menu.getAttribute('data-menu-url') ?? '#';
	mobileMenuTitleLink.setAttribute('href', headerLink);
};

const hideSubMenuHeader = () => {
	mobileMenuHeader.classList.remove('show');
	mobileMenuTitle.innerText = '';
};

const showRootMenu = () => {
	hideSubMenuHeader();
	mobileMenuLinks.forEach((menu) => {
		if (menu.classList.contains('root-menu')) {
			menu.classList.add('show');
		}
	});
};

const resetNavigation = () => {
	hideAllMenus();
	showRootMenu();
	activeMenu.length = 0;
	document.querySelector('#pay-dropdown').classList.remove('show');

    // hide search autocomplete
    $('.search-dialog').removeClass('show');
    $('.search-list').each(function () {
        $(this).removeClass('show');
    });
};

const navigationHandler = () => {
	// toggle nav toggler / hamburger
	mobileNavToggler.addEventListener('click', (e) => {
		mobileMenu.classList.toggle('show');
	});

	desktopNavToggler.addEventListener('click', (e) => {
		mobileMenu.classList.toggle('show');
	});

    mobileSearchButton.addEventListener('click', (e) => {
        mobileSearchDialog.classList.toggle('show');
        document.querySelector('#search-input').focus();
    });

    // topSearchInput.addEventListener('click', () => {
    //     mobileSearchDialog.classList.toggle('show');
    //     document.querySelector('#search-input').focus();
    // });

	// nav menu links
	mobileMenuLinks.forEach((menuLink) => {
		menuLink.addEventListener('click', (e) => {
			const dataMenu = e.currentTarget.getAttribute('data-menu');
			activeMenu.push(dataMenu);
			const nextMenu = document.querySelector(`[data-submenu="${dataMenu}"]`);
			if (nextMenu) {
				showNextMenu(nextMenu);
			}
		});
	});

	// back to prev menu
	mobileMenuBack.addEventListener('click', () => {
		getPrevMenu();
	});

	// reset menu
	mobileMenuReset.addEventListener('click', () => {
		hideAllMenus();
		showRootMenu();
		document.querySelector('#pay-dropdown').classList.remove('show');
	});

	// toggle mobile pay buttons
	mobilePayButton.addEventListener('click', () => {
		document.querySelector('#pay-dropdown').classList.toggle('show');
	});
};

let windowWidth = window.innerWidth;

const windowResizer = () => {
	window.addEventListener('resize', () => {
        if (windowWidth != window.innerWidth) {
            resetNavigation();
            mobileMenu.classList.remove('show');
            windowWidth = window.innerWidth;
        }
	});
};

const courseNavLink = document.querySelector('#course-nav-link');
const courseNavContainer = document.querySelector('#course-nav');
const courseListContainers = document.querySelectorAll('.course-list');
const courseCatLinks = document.querySelectorAll('.course-cat-link');
const courseCatContainers = document.querySelectorAll('.course-cat');
let activeCourseCat = 'in-class';
const subNavs = document.querySelectorAll('.sub-nav');
const catHeaderLinks = document.querySelectorAll('.sub-nav-header .header-link');
const desktopNavLinks = document.querySelectorAll('.navigation-link');

// Desktop Navigation
const courseNavHandler = () => {
	showCourseCat();

	desktopNavLinks.forEach((link) => {
		link.addEventListener('mouseover', (e) => {
            subNavs.forEach((subNav) => {
                subNav.classList.remove('delay');
                subNav.style.display = 'none';
                // subNav.classList.remove('show');
                // subNav.style.display = 'block';
            });
			const subNav = link.querySelector(':scope > .sub-nav');
			if (subNav) {
                subNav.style.display = 'block';
				subNav.classList.add('delay');
				subNav.classList.add('show');
			}
		});

		link.addEventListener('mouseout', (e) => {
			const subNav = link.querySelector(':scope > .sub-nav');
			if (subNav) {
				subNav.classList.remove('show');
			}
		});
	});

	subNavs.forEach((subNav) => {
		subNav.addEventListener('mouseout', (e) => {
			subNav.classList.remove('show');
		});
	});

	courseCatLinks.forEach((link) => {
		link.addEventListener('click', (e) => {
			const courseList = e.currentTarget.nextElementSibling;
			resetActiveCourseCatLink();
			hideAllCourseLink();
			if (courseList) {
				e.currentTarget.classList.toggle('active');
				courseList.classList.toggle('show');
			}
		});
	});

	catHeaderLinks.forEach((link) => {
		if (link.getAttribute('data-menu') == activeCourseCat) {
			link.classList.add('active');
		}

		link.addEventListener('click', (e) => {
			resetActiveHeaderLink();
			activeCourseCat = e.target.getAttribute('data-menu');
			link.classList.add('active');
			showCourseCat();
		});
	});
};

const showCourseCat = () => {
	resetActiveCourseCatLink();
	hideAllCourseLink();
	courseCatContainers.forEach((container) => {
		let activeCat = activeCourseCat === 'in-class' || activeCourseCat === 'remote' ? 'public' : activeCourseCat;
		container.classList.remove('active');
		if (container.getAttribute('data-menu') === activeCat) {
			container.classList.add('active');
		}
	});
};

const resetActiveHeaderLink = () => {
	catHeaderLinks.forEach((link) => {
		link.classList.remove('active');
	});
};

const resetActiveCourseCatLink = () => {
	courseCatLinks.forEach((link) => {
		link.classList.remove('active');
	});
};

const hideAllCourseLink = () => {
	courseListContainers.forEach((courseListContainer) => {
		courseListContainer.classList.remove('show');
		courseListContainer.style.top = 0 + 'px';
	});
};

const initCountrySelectors = () => {
	const countrySelectors = document.querySelectorAll('.book-now.region');

	countrySelectors.forEach((selector) => {
		selector.addEventListener('click', (e) => {
			const dropdownContainer = selector.querySelector('.dropdown-container');
			if (!dropdownContainer.style.display || dropdownContainer.style.display === 'none') {
				dropdownContainer.style.display = 'block';
			} else {
				dropdownContainer.style.display = 'none';
			}
		});
	});
};

window.onload = () => {
	document.querySelector('#pay-button-mobile').addEventListener('click', (e) => {
		document.querySelector('.dropdown-container-mobile').classList.toggle('show');
	});

	document.querySelector('#pay-button').addEventListener('mouseover', (e) => {
		document.querySelectorAll('.dropdown-container > .btn').forEach((btn, i) => {
			btn.style.top = (i + 1) * 36 + 'px';
		});
	});

	document.querySelector('#pay-button').addEventListener('mouseout', (e) => {
		document.querySelectorAll('.dropdown-container > .btn').forEach((btn, i) => {
			btn.style.top = 0;
		});
	});

	$('.closecountry').click(function () {
		$('.countrybanner').hide();
	});

	// init menu state
	resetNavigation();

	// close nav on outside click
	windowDetector();

	// nav menu handler
	navigationHandler();

	// window Resizer
	windowResizer();

	// course link handler
	courseNavHandler();

	// country selector init
	initCountrySelectors();
};
