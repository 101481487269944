document.querySelectorAll(".accordian-p").forEach((item) => { item.classList.add("accordian") });

document.querySelectorAll(".accordian-p .accordian-item").forEach((row, i) => {
	if (i == 0) {
		row.classList.add("selected");
		console.log(row.children[0])
	}

	//	row.children[0].innerHTML = `${row.children[0].innerHTML}</div>`;

	//row.children[0].classList.add("card-header")
	row.children[0].addEventListener("click", () => {
		row.parentElement.querySelectorAll(".accordian-item.selected").forEach((allRow) => {
			allRow.classList.remove("selected");
		})

		row.classList.add("selected")
	});
});


var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
	acc[i].addEventListener("click", function () {
		this.classList.toggle("active");
		var panel = this.nextElementSibling;
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null;
		} else {
			panel.style.maxHeight = panel.scrollHeight + "px";
			if ($(".panel").hasClass("city-links-courses")) {
				panel.style.maxHeight = 600 + "px";
			}
		}
	});
}



