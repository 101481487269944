/*xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1900px
*/

export const isXSMedia = window.matchMedia("(max-width: 576.8px)");
export const isSMMedia = window.matchMedia("(min-width: 577px) AND (max-width: 767.8px)");
export const isMDMedia = window.matchMedia("(min-width: 768px) AND (max-width: 991.8px)");
export const isLGMedia = window.matchMedia("(min-width: 992px) AND (max-width: 1199.8px)");
export const isXLMedia = window.matchMedia("(min-width: 1200px) AND (max-width: 1899.8px)");
export const isXXLMedia = window.matchMedia("(min-width: 1900px)");

export const allMedia = [isXSMedia, isSMMedia, isMDMedia, isLGMedia, isXLMedia, isXXLMedia]

const matches = {
	isXS: isXSMedia.matches,
	isSM: isSMMedia.matches,
	isMD: isMDMedia.matches,
	isLG: isLGMedia.matches,
	isXL: isXLMedia.matches,
	isXXL: isXXLMedia.matches
}

export const isLessThanOrEqual = (name) => {
	let keys = Object.keys(matches);
	const index = keys.findIndex((str) => str === name);

	if(index !== -1){
		keys = keys.splice(0, index + 1);
		return keys.map((key) => matches[key]).includes(true)
	}else{
		return false;
	}
}


isXSMedia.addListener((e) => {
	matches.isXS = e.matches
})

isSMMedia.addListener((e) => {
	matches.isSM = e.matches
})

isMDMedia.addListener((e) => {
	matches.isMD = e.matches
})

isLGMedia.addListener((e) => {
	matches.isLG = e.matches
})

isXLMedia.addListener((e) => {
	matches.isXL = e.matches
})

isXXLMedia.addListener((e) => {
	matches.isXXL = e.matches
})

export default matches;