import dayjs from 'dayjs'
import Tooltip from "../../bootstrap/tooltip";
import { isLessThanOrEqual as isLessThanOrEqualResponsive } from "../../responsive";

var waitInitCoursesDatesMacro = setInterval(() => {
	if ($(".course-dates-macro")[0] && window.app.asyncPartialViewsLoaded()) {
		initCoursesDatesMacro();
		clearInterval(waitInitCoursesDatesMacro);
	}
}, window.app.waitingTimeMilliSeconds);

var waitInitCourseContent = setInterval(() => {
	if ($(".course-content.panel.active")[0]) {
		initCourseContent();
		clearInterval(waitInitCourseContent);
	}
}, window.app.waitingTimeMilliSeconds);

function trafficLightToCSS(course) {
	if (course["isLightGreen"]) {
		return "scheduled";
	} else if (course["isLightYellow"]) {
		return "confirmed";
	} else if (course["isLightOrange"]) {
		return "fewSeats";
	} else if (course["isLightPurple"]) {
		return "soldOut";
	} else {
		return "unknown";
	}
}

function prettyString(str) {
	const result = str.split(/(?=[A-Z])/).join(" ");
	return result.substr(0, 1).toUpperCase() + result.substr(1);
}

function initCoursesDatesMacro() {
	if (document.querySelector(".course-dates-macro")) {
		// Tabs
		$(".course-dates-macro .toolbar > div, .course-dates-macro .mobile-title").click(function () {
			const id = $(this).data("id");

			$(this)
				.parent()
				.children()
				.removeClass("selected");
			$(this).addClass("selected");

			var page = $('.course-dates-macro .course-date-list[data-id="' + id + '"]');
			$(".course-dates-macro .course-date-list").removeClass("selected");
			page.addClass("selected");

			// Handle hide/show bluebox
			$('.course-details').removeClass("selected");
			$(`.course-details.box-toggle-id-${id}`).addClass("selected")




			if (isLessThanOrEqualResponsive("isMD")) {
				//this.scrollIntoView();
			}
		});

		$(".show-less").click(function () {
			var courseContainer = $($(this).parents(".course-date-list")[0]);
			var page = (courseContainer.data("page") || 1);

			if (page > 1) {
				courseContainer.find(".date-row").filter((i, item) => {
					return $(item).data("from-page") == page
				}).remove();

				courseContainer.data("page", page - 1);
			};
		});

		$(".show-more").click(function () {
			var button = $(this);
			var courseContainer = $($(this).parents(".course-date-list")[0]);

			var id = courseContainer.data("id");
			var page = (courseContainer.data("page") || 1) + 1;

			var url = apiUrl + moreUrl.replace("PAGENUMBER", page).replace("COURSEID", id);

			button
				.text("Loading")
				.attr("disabled", "disabled");

			$.get(url).then(function (data) {
				if (data.results.length == 0) {
					return;
				}
				var dateContainer = courseContainer.find(".date-list-container");

				var tabOfficeName = $('.course-dates-macro .toolbar .selected').text();

				data.results.map(row => {
					const date = dayjs(row.date);
					const durationSource = Math.ceil(row.duration / courseLengthDayInHours);
					var dateRangeMarkUp = getCourseDateMarkUp(durationSource, date);
					var light = trafficLightToCSS(row);
					var template = `<div class="date-row" data-from-page="${page}">
							${dateRangeMarkUp}
							<div class="title">${row.courseName}</div>
							<div class="city">${row.office || defaultOfficeLabel || tabOfficeName || ''}</div>
							<div class="trafficlight-mobile">Status: ${prettyString(light)}</div>
							<div class="trafficlight-text ${light}-text">
								${prettyString(light)}
							</div>
							<div class="trafficlight"><div class="traffic ${light}"> </div></div>
							<div class="book"><a href="/book-now/?sessionId=${row.sessionId}" class="${light}">
								${light == "soldOut" ? "Full" : "Book <span class='now'> Now</span>"}
							</a></div>
						</div>`;


					dateContainer.find('.actions').before($.parseHTML(template));

					button
						.text("show more")
						.removeAttr("disabled")

					courseContainer.data("page", page);
				})


			}).then(() => {
				button
					.text("show more")
					.removeAttr("disabled")

				bindTooltips();
			})
		});

		function getCourseDateMarkUp(duration, fromDate) {
			const dateRange = getDateRangeText(duration, fromDate);
			let markup = "<div class='date'>{{desktopMarkup}}</div>";
			let desktopMarkup = "";
			if (dateRange.daysText.indexOf("-") > -1) {
				desktopMarkup = `<div>${dateRange.daysText.replace("-", " - ")}</div><div>${dateRange.dateMonthRangeText}&nbsp;${dateRange.yearText}</div>`;
			} else {
				desktopMarkup = `<div>${dateRange.daysText}&nbsp;${dateRange.dateMonthRangeText}&nbsp;${dateRange.yearText}</div>`;
			}
			markup = markup.replace("{{desktopMarkup}}", desktopMarkup);
			markup = markup +
				`<div class="date-mobile">
					<div>${dateRange.daysText}</div>
					<span class="day">${dateRange.dateRangeText}</span>
					<div>${dateRange.monthRangeText}</div>
				</div>`;

			return markup;
        }

		function getDateRangeText(duration, fromDate) {
			if (duration === 1 || !duration) {
				return {
					daysText: fromDate.format("ddd"),
					dateMonthRangeText: fromDate.format("D MMM"),
					dateRangeText: fromDate.format("D"),
					monthRangeText: fromDate.format("MMM"),
					yearText: fromDate.format("YY")
				}
			} 

			duration--;
			let toDate = fromDate.add(duration.toString(), 'day');
			if (toDate.month() === fromDate.month()) {
				return getTextModelRange(fromDate, toDate);
			} else {
				let model = getTextModelRange(fromDate, toDate);
				model.monthRangeText = `${fromDate.format("MMM")}-${toDate.format("MMM")}`;
				return model;
            }
		}

		function getTextModelRange(fromDate, toDate) {
			return {
				daysText: `${fromDate.format("ddd")}-${toDate.format("ddd")}`,
				dateMonthRangeText: `${fromDate.format("D MMM")} - ${toDate.format("D MMM")}`,
				dateRangeText: `${fromDate.format("D")}-${toDate.format("D")}`,
				monthRangeText: fromDate.format("MMM"),
				yearText: fromDate.format("YY")
			}
        }


		function bindTooltips() {
			const messages = {
				".soldOut": "Sold Out.<br/> Try another date.",
				".fewSeats": "Few Seats left.<br/> Book Now.",
				".confirmed": "Confirmed.<br/> Taking enrollments.",
				".scheduled": "Scheduled.<br/> Taking enrollments.",
			}

			for (let [selector, title] of Object.entries(messages)) {
				for (let element of document.querySelectorAll(selector + "," + selector + "-text")) {
					if (!element.classList.contains("bound")) {
						new Tooltip(element, {
							title: title,
							html: true,
							placement: "bottom"
						})

						element.classList.add("bound")
					}
				}
			}
		}

		bindTooltips();


		// Handle Tab changing for blue box hide/show





	}
}

function initCourseContent() {
	$(".course-content.panel.active > ul li").click(function () {

		if (!isLessThanOrEqualResponsive("isSM")) {
			return;
		}

		var len = $(this).siblings().filter(function () {
			return $(this).css('display') == 'none';
		});

		if (len.length >= 1) {
			$(this).toggleClass('active');
			$(this).siblings().show();


		} else {
			$(this).toggleClass('active');
			$(this).siblings().hide();

		}

	});
}