var loc = window.location;
var partialViewPath = "/umbraco/surface/AsyncPartialViews/";
var baseUrl = loc.protocol + "//" + loc.hostname + (loc.port ? ":" + loc.port : "");

var waitInitAsyncPartialViews = setInterval(() => {
    if ($(".async-partial-view")[0]) {
        initAsyncPartialViews();
        clearInterval(waitInitAsyncPartialViews);
    }
}, window.app.waitingTimeMilliSeconds);

function initAsyncPartialViews() {
    $(".async-partial-view").each(function (index, item) {
        var params = getParams(item);
        var url = encodeURI(baseUrl + partialViewPath + $(item).data("method") + params);
        if (url && url.length > 0) {
            $(item).load(url);
        }
    });
}

function getParams(item) {
    var params = "?";
    var attributes = item.attributes;
    for (var i = 0; i < attributes.length; i++) {
        var attr = attributes[i];
        var attrName = attr.name;
        if (attrName.indexOf("param-") !== -1) {
            var paramItem = attrName.replace("param-", "") + "=" + attr.value + "&";
            params += paramItem;
        }
    }

    params = params.slice(0, -1);
    return params;
}