require("expose-loader?exposes=$,jQuery!jquery");
require("jquery-validation/dist/jquery.validate.min.js");
require("glider-js/glider.min.js");
require("iframe-resizer/js/iframeResizer.js");


document.querySelectorAll("iframe.autosize").forEach((iframe) => {
    iframe.addEventListener("load", () => {
        iframe.parentNode.classList.remove("loading");
        // run iFrameResizer in this load event (was run inline previously)
        iFrameResize({
            log: false, onResized: (messageData) => {
                // Debug console.log('Message Data');console.log(messageData);console.log(messageData.iframe.parentNode.classList.contains('loading'));
                if (messageData.iframe.parentNode.classList.contains("loading")) {
                    messageData.iframe.parentNode.classList.remove("loading")
                }
            }
        }, iframe);
    })
})

if(!!window.MSInputMethodContext && !!document.documentMode){
    document.getElementsByTagName("body")[0].classList.add("isIE11");
}

if (!window.app)
    window.app = {};

window.app.waitingTimeMilliSeconds = 500;

window.app.asyncPartialViewsLoaded = () => {
    const asyncSpinners = document.querySelectorAll(".async-partial-view .spinner");
    return asyncSpinners.length == 0;
}

require("./contact-form.js");
require("./global/async/header.js");
require("./global/async/slider.js");
require("./global/async/courses.js");
require("./global/async/search.js");
require("./accordian.js");
require("./global/async/async-partial-view");