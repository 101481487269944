function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

function search(input, data) {
	return data.split(' ').join('').toLowerCase().includes(input.split(' ').join('').toLowerCase());
}

function searchEvent(e) {
    const searchVal = e.target.value;
    $('.search-list').each(function (e) {
        const menuData = $(this).attr('data-value');
        if (searchVal.length >= 2 && search(searchVal, menuData)) {
            $(this).addClass('show');
        } else {
            $(this).removeClass('show');
        }
    });
}

function searchDialogEvent(e) {
    const searchVal = e.target.value;
    let count = 0;
    $('.search-dialog-list').each(function (e) {
        const menuData = $(this).attr('data-value');
        if (searchVal.length >= 2 && search(searchVal, menuData) && count <= 8) {
            count++;
            $(this).addClass('show');
        } else {
            $(this).removeClass('show');
        }
    });
}

$(document).ready(function () {
	$('#top-search-input').click(function (e) {
		searchEvent(e);
	});

	$('#top-search-input').keyup(
		debounce(function (e) {
			searchEvent(e);
		})
	);

    $('#search-input').keyup(
		debounce(function (e) {
			searchDialogEvent(e);
		})
	);

    $('.search-filter').on('change', function(e) {
        $('#search-button').click();
    })
});
